import Headroom from 'headroom.js';

(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  const menuTrigger = document.querySelector('#main-menu-trigger');
  const header = document.querySelector('.main-header');
  const creditsTrigger = document.querySelector('#open-credits');
  const creditsLayer = document.querySelector('#credits-layer');
  const creditsClose = document.querySelector('#credits-close');
  const scrollableLinks = document.querySelectorAll('.has-scrollable');

  function handleDOMContentLoaded() {
    menuTrigger.addEventListener('click', handleMenu);

    if (creditsTrigger) {
      creditsTrigger.addEventListener('click', handleOpenCredits);
      creditsClose.addEventListener('click', handleCloseCredits);
    }

    new Headroom(header, {
      offset: {
        down: 50,
        up: 100
      },
      tollerance: {
        up: 30,
        down: 0
      },
      classes: {
        notTop: 'main-header--short'
      }
    }).init();

    scrollableLinks.forEach((el) => el.addEventListener('click', handleScrollable));
  }

  function handleMenu(e) {
    e.preventDefault();

    if (e.currentTarget.classList.contains('is-active')) {
      e.currentTarget.classList.remove('is-active');
      header.classList.remove('main-header--with-menu');
    } else {
      e.currentTarget.classList.add('is-active');
      header.classList.add('main-header--with-menu');
    }
  }

  function handleOpenCredits(e) {
    e.preventDefault();

    creditsLayer.classList.add('credits-layer--show');
  }

  function handleCloseCredits(e) {
    e.preventDefault();

    creditsLayer.classList.remove('credits-layer--show');
  }

  function handleScrollable(e) {
    e.preventDefault();
    const href = e.currentTarget.getAttribute('href');

    if (header.classList.contains('main-header--with-menu')) {
      menuTrigger.click();
    }

    document.querySelector(href).scrollIntoView({
      behavior: 'smooth'
    });
  }
})();
